import React from 'react';
import { Card, Flex, Progress } from 'antd';
import { Link } from 'react-router-dom';
import "../../../../index.scss";
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';

const conicColors = {
   '0%': '#237804',
   '20%': '#a0d911',
   '40%': '#ffec3d',
   '60%': '#faad14',
   '80%': '#fa8c16',
   '100%': '#f5222d',
};
const CmcKeysUsageDashboard = ({
   cmcKeysUsage
}) => {
   const usagePercentage = ((cmcKeysUsage?.currentMonthRequestsMade / cmcKeysUsage?.creditLimitMonthly) * 100) || 0;

   const statusIcon = 'exception';

   return (
      <>
         <Card
            style={{ minHeight: '400px' }}
            title=""
            extra={
               <Link className="linkButton" to="/price-source/cmc/keylist">
                  Show more
               </Link>
            }
         >
            <Flex gap="small" wrap>
               <Progress
                  type="circle"
                  percent={usagePercentage.toFixed(2)}
                  strokeColor={conicColors}
                  showInfo={true}
                  status={statusIcon}
                  format={(usagePercentage) => `${usagePercentage}%`}
                  strokeWidth={10}
                  size={175}
               />
               <p>
                  <p> <b> Platform Status:</b> {cmcKeysUsage?.cmc_key_status?.toUpperCase()} {cmcKeysUsage?.cmc_key_status == 'active' ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <StopTwoTone twoToneColor="#eb2f96" />}  </p>
                  <p> <b> Active CMC Keys:</b> {cmcKeysUsage?.active_key_count} </p>
                  <p> <b> Last updated at:</b> {cmcKeysUsage?.last_updated_at} </p>
                  <p> <b> Current Month Usage:</b> {cmcKeysUsage?.currentMonthRequestsMade} </p>
                  <p> <b> Monthly Credit Limit:</b> {cmcKeysUsage?.creditLimitMonthly} </p>
               </p>
            </Flex>
         </Card>
      </>
   );
}
export default CmcKeysUsageDashboard;


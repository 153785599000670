import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import { Breadcrumb, Card, Pagination, Row, Space, Table } from "antd";

import VenderFilter from "./component/venderFilter.comp";
import ActionButtonsVenders from "./component/actionBtns.comp";
import ExtraInfo from "./component/extraInfo.comp";
import AddNewVender from "./component/addVender.comp";

import {
   useVenderListMutation,
} from "../../../redux/services/venderApi";
import { convertAbbreviateNumber } from "../../../Helper/utility.helper";

const defaultCurrent = 1;
const defaultPageSize = 15;

const VendersPage = () => {
   const [selectedValue, setSelectedValue] = useState([]);
   const [currentPage, setCurrentPage] = useState(defaultCurrent);
   const [pageSize, setPageSize] = useState(defaultPageSize);
   const [apiResponse, setApiResponse] = useState("");
   const { loading } = useSelector((state) => state?.loading);
   const { success } = useSelector((state) => state?.toastState);

   const [fetchVenderList, { data: getVenderList }] = useVenderListMutation();

   const VenderList = getVenderList?.data;
   const totalCount = getVenderList?.data?.count;

   const searchKeywordFn = (name) => {
      fetchVenderList({
         keyword: name,
         status: selectedValue,
         page: currentPage,
         limit: pageSize,
      })?.then((resp) => {
         setApiResponse(resp?.data?.status);
      });
   };

   const resetKeywordFn = () => {
      setCurrentPage(defaultCurrent);
      setPageSize(defaultPageSize);
      fetchVenderList({
         keyword: "",
         status: "",
         page: defaultCurrent,
         limit: defaultPageSize,
      });
   };

   const onPageChange = (pageNumber, pageSize) => {
      setCurrentPage(pageNumber);
      setPageSize(pageSize);
      fetchVenderList({
         page: pageNumber,
         limit: pageSize,
         status: "",
         keyword: "",
      });
   };

   useEffect(() => {
      fetchVenderList({
         status: "",
         keyword: "",
         page: currentPage,
         limit: pageSize,
      });
   }, []);

   const columns = [
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
         render: (text, record) => {
            return record?.name.charAt(0).toUpperCase() + record?.name.slice(1);
         },
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Contact Number",
         dataIndex: "contact_no",
         key: "contact_no",
      },
      {
         title: "Status",
         dataIndex: "status",
         key: "status",
         render: (text, record) => {
            return record?.status.toUpperCase();
         },
      },
      {
         title: "Plan",
         // dataIndex: "plan",
         key: "plan",
         render: (text, record) => {
            return record?.api_hits_plan ?  `${record?.api_hits_plan?.title} (${convertAbbreviateNumber(record?.api_hits_plan?.monthly_hits || 0)})` : 'none'
         },
      },
      {
         title: "Plan Valid upto",
         dataIndex: "plan_valid_up_to",
         key: "plan_valid_up_to",
         render: (text, record) => {
            // return record?.plan_renew_date;
            return record?.plan_valid_up_to ? moment.utc(record?.plan_valid_up_to).format("DD-MMM-YYYY - HH:mm:ss A") : ""
         },
      },
      {
         title: '',
         key: "action",
         render: (text, record, index) => {
            return (
               <>
                  {
                     record?.status == "pending" ? (
                        <ActionButtonsVenders
                           vender={record}
                           fetchVenderList={fetchVenderList}
                           selectedValue={selectedValue}
                           currentPage={currentPage}
                           pageSize={pageSize}
                        />
                     ) : (
                        <Link className="linkButton" type="primary" to={"/vender-details/" + record.id}> View Details </Link>
                     )
                  }
               </>
            );
         },
      },
   ];

   return (
      <>
         <Card>
            <Breadcrumb items={[{ title: "Dashboard" }, { title: "Venders" }]} />
         </Card>

         <Card title="Vender List" style={{ border: "none" }} extra={
            <Space>
               <AddNewVender
                  fetchVenderList={fetchVenderList}
                  selectedValue={selectedValue}
                  pageSize={pageSize}
                  currentPage={currentPage}
               />
            </Space>
         }>
            <VenderFilter
               searchKeywordFn={searchKeywordFn}
               resetKeywordFn={resetKeywordFn}
               setSelectedValue={setSelectedValue}
               selectedValue={selectedValue}
               apiResponse={apiResponse}
               pageSize={pageSize}
               currentPage={currentPage}
            />
         </Card>


         <Table pagination={false}
            dataSource={VenderList?.rows}
            columns={columns}
            loading={loading && true}
            expandable={{
               expandedRowRender: (record) => (
                  <>
                     <ExtraInfo
                        vender={record}
                        fetchVenderList={fetchVenderList}
                        selectedValue={selectedValue}
                        pageSize={pageSize}
                        currentPage={currentPage}
                     />
                  </>
               ),
               rowExpandable: (record) => record.name !== "Not Expandable",
            }}
         />

         <Row className="PaginationRow justifyContentCenter">
            {totalCount >= 10 && (
               <Pagination
                  showQuickJumper
                  current={currentPage}
                  total={totalCount}
                  pageSize={pageSize}
                  onChange={onPageChange}
                  responsive={true}
               />
            )}
         </Row>

      </>
   )
}
export default VendersPage;
